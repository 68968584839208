<template>
	<main class="main">
		<section class="container" v-if="blocks">
      <Crumbs :data="crumbs"></Crumbs>
			<TopBlock
				:data="{ ...blocks[0], ...aniamtionContent }"
				:advantages="blocks[1]"
			></TopBlock>
		</section>
		<section class="container" v-if="blocks" style="margin-top: -45px;">
			<div v-if="blocks">
				<h3 class="main__title">
					<a class="link link-to--bottom" @click="scrollMeTo('main__map')">Посмотреть пункты выдачи</a>
				</h3>
			</div>
		</section>
		<section class="main__full-box main__gradient">
			<div class="container flex flex--middle">
				<img
					:src="require('@/assets/images/pictures/image_how-to-get-a-card.png')"
					alt="Как получить карту"
					class="main__full-box-image main__full-box-image--bottom"
				/>
				<div class="main__full-box-content" v-if="blocks">
					<h2 class="main__title">{{ blocks[2].title }}</h2>
					<div
						class="editor editor--list-white"
						v-html="blocks[2].content"
					></div>
				</div>
			</div>
		</section>
		<section class="container">
			<div class="main__box">
				<Steps :data="steps"></Steps>
				<!--
				<p
					class="main__text text-center text-medium mt-75 mb-75 m-0-auto mw-780"
				>
					Вы можете оставить предварительную заявку на оформление нефинансовой
					Единой социальной карты
				</p>
				<div class="flex flex--center">
					<button class="button button--big" @click="showModalRequest">
						Оставить заявку
					</button>
				</div>
-->
			</div>
		</section>

		<section class="container">
			<div class="main__box" ref="main__map">
				<Map
					:data="{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						groupedMarkers: getGroupedPoints,
						title: 'Пункты приема заявлений и выдачи ЕСК',
					}"
					:filters="filters"
				></Map>
			</div>
		</section>
	</main>
</template>

<script>
import TopBlock from '@/components/TopBlock.vue'
import Steps from '@/components/Steps.vue'
import Map from '@/components/Map.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'HowToGetACard',

	components: {
		TopBlock,
		Steps,
		Map,
		Crumbs,
	},

	data() {
		return {
			steps: null,
			aniamtionContent: {
				animationBg: require('@/assets/images/animation/animation_circle-green.svg'),
				animationFront: require('@/assets/images/animation/animation_credit-card-new.png'),
				animationClassBg: 'pulse',
				animationClassFront: 'slide-left',
			},
			crumbs: null,
		}
	},

	methods: {
		scrollMeTo(refName) {
			var element = this.$refs[refName];
			//var top = element.offsetTop;
			//window.scrollTo(0, top);
          	element.scrollIntoView();
		},
		showModalRequest() {
			this.$store.dispatch('GET_MODAL', { type: 3 })
		},
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},

		getGroupedPoints() {
			return this.$store.getters.POINTS_ORGANIZATION_GROUPED
		},

		filters() {
			return {
				cardType: false,
				list: false,
				type: 'points',
				store: {
					dispatch: 'SET_POINT_LIST_APPLIED_FILTERS',
					getters: 'POINT_LIST_APPLIED_FILTERS',
				},
				filters: [
					{
						name: 'Фильтр',
						data: [
							{
								type: 'checkbox',
								cardType: true,
								title: 'Тип карты',
								list: [
									{
										id: 'isBankCardIssuer',
										name: 'Банковская',
									},
									{
										id: 'isNonFinancialCardIssuer',
										name: 'Нефинансовая',
									},
								],
							},
							{
								type: 'checkbox',
								title: 'Пункт выдачи ЕСК',
								list: this.$store.getters.POINT_LIST_FILTERS,
							},
						],
					},
				],
			}
		},
	},

	mounted() {

//   const el = document.querySelector(this.$route.hash)
//   el && el.scrollIntoView()



		this.$store.dispatch('GET_POINTS_ORGANIZATION')
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})

		this.steps = {
			class: 'steps--hover',
			list: [
				{
					image: require('@/assets/images/icons/icon_building.svg'),
					title: '1-й шаг',
					description: 'Найти отделение',
					content:
						'Выберите на карте ближайшее отделение банка-партнера или пункт выдачи нефинансовых карт.',
				},
				{
					image: require('@/assets/images/icons/icon_statement.svg'),
					title: '2-й шаг',
					description: 'Заполнить заявление',
					content:
						'Для оформления заявления Вам понадобится паспорт и СНИЛС. Заполнить заявление Вам поможет сотрудник отделения.',
				},
				{
					image: require('@/assets/images/icons/icon_credit-card-big-white.svg'),
					title: '3-й шаг',
					description: 'Получить карту',
					content:
						'Достаточно одного визита в отделение - готовую карту вы получите уже через 15 минут и сразу же сможете начать пользоваться всеми сервисами.',
				},
			],
		}

    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Как получить карту',
      },
    ]
	},
}
</script>
